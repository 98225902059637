import React from "react";
import LocalizedLink from "../LocalizedLink";

const Btn = ({ name, link, ...props }) => {
  return (
    <div>
      <LocalizedLink
        to={link}
        type={props.type}
        activeClassName="active"
        aria-label={name}
        className={`btn ${props.className}`}
      >
        {props.children}
      </LocalizedLink>
    </div>
  );
};

export default Btn;
