import React, { useEffect, useRef, useState } from "react";

import loadable from "@loadable/component";

const Odometer = loadable(() => import("react-odometerjs"));

const OdometerBox = ({ preText, text, number, stats }) => {
  const odometer = useRef(null);
  const [value, setValue] = useState(number);
  const [executed, setExecuted] = useState(false);

  function handleAnimate() {
    if (!executed && stats) {
      const boxPosition = stats.current.getBoundingClientRect();
      const boxPositionTop = boxPosition.top;
      const boxRowHeight = boxPosition.height;
      if (boxPositionTop - window.innerHeight / 2 + boxRowHeight / 3 < 0) {
        setValue(0);
        setValue(number);
        setExecuted(true);
      }
    }
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", handleAnimate);
    }

    watchScroll();
    return () => {
      window.removeEventListener("scroll", handleAnimate);
    };
  });

  return (
    <div className="stats__box">
      <div className="stats__data">
        {preText && <div className="stats__value-txt">{preText}</div>}
        <div ref={odometer} className="stats__value-number">
          <Odometer format="d" duration={3000} value={value} />
        </div>

        <div className="stats__value-txt">{text}</div>
      </div>
    </div>
  );
};

export default OdometerBox;
