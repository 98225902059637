import React, { useRef, useEffect, useState } from "react";
import ServiceItem from "./servicesItem";

const ServiceList = (props) => {
  const line = useRef(null);
  const dotsRef = useRef([]);
  const itemsRef = useRef([]);
  const [currentClass, setCurrentClass] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isRender, setRender] = useState(false);
  const [stateMargin, setMargin] = useState([]);
  const [stateDots, setDots] = useState([]);

  const margin = [];
  const dots = [];
  const getHeight = (i) => {
    const height = (window.innerHeight * 0.6 * 0.8) / 4;
    return height * i + window.innerHeight * 0.07;
  };

  const updateSizePage = () => {
    for (let i = 0; i < 4; i++) {
      const dotHeight = getHeight(i);
      itemsRef.current[i].style.marginTop = `${dotHeight}px`;
      dotsRef.current[i].style.top = `${dotHeight}px`;
    }
  };

  function layout() {
    if (props.content && !isRender) {
      for (let i = 0; i < props.content.length; i++) {
        const dotHeight = getHeight(i);
        const time = i / 2.2;
        dots[i] = (
          <div
            ref={(el) =>
              dotsRef.current.length < props.content.length
                ? dotsRef.current.push(el)
                : null
            }
            className="dot"
            style={{ top: `${dotHeight}px`, animationDelay: `${time * 0.65}s` }}
          ></div>
        );
        margin[i] = `${dotHeight}px`;
      }
      setMargin(margin);
      setDots(dots);
      setRender(true);
    }
  }

  function handleAnimate() {
    if (
      line.current.getBoundingClientRect().top - window.innerHeight <= 0 &&
      isFirstTime
    ) {
      setCurrentClass("draw");
      setIsFirstTime(false);
    }

    dotsRef.current.forEach((el) => {
      if (el != null) {
        const positionFromTop = el.getBoundingClientRect().top;
        if (positionFromTop - window.innerHeight <= 0) {
          el.classList += el.classList.contains("dot-animation")
            ? ""
            : " dot-animation";
        }
      }
    });

    for (let i = 0; i < itemsRef.current.length; i++) {
      if (itemsRef.current[i] != null) {
        const positionFromTop = itemsRef.current[i].getBoundingClientRect().top;
        if (positionFromTop - window.innerHeight <= 0) {
          itemsRef.current[i].classList.remove("hidden");
          itemsRef.current[i].classList.add("fade-in-element");
          itemsRef.current[i].style.animationDelay = `${(i / 3) * 0.75}s`;
        }
      }
    }
  }

  useEffect(() => {
    layout();
    if (props.content) {
      function watchScroll() {
        window.addEventListener("scroll", handleAnimate);
      }
      function updateLayout() {
        window.addEventListener("resize", updateSizePage);
      }
      updateLayout();
      watchScroll();
    }
    return () => {
      if (props.content) {
        window.removeEventListener("scroll", handleAnimate);
        window.removeEventListener("resize", updateSizePage);
      }
    };
  });

  return (
    <ul className="service">
      {props.content &&
        isRender &&
        props.content.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <li
                className="hidden service__list-item col-12 col-sm-6 col-md-6 col-lg-4"
                style={{ marginTop: stateMargin[index] }}
                ref={(el) =>
                  itemsRef.current.length < props.content.length
                    ? itemsRef.current.push(el)
                    : null
                }
              >
                <ServiceItem
                  title={item.title}
                  description={item.description}
                  className={item.thumbnail.className}
                />
              </li>
              {index + 1 === Math.floor(props.content.length / 2) && (
                <div
                  className={`service__line-wrapper ${currentClass}`}
                  ref={line}
                >
                  {stateDots}
                </div>
              )}
            </React.Fragment>
          );
        })}
    </ul>
  );
};

export default ServiceList;
