import React, { useRef, useEffect, useState } from "react";

const AnimateTitle = (props) => {
  const container = useRef(null);
  const important = useRef(null);
  const [currentClass, setCurrentClass] = useState("hidden");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [reveal, setReveal] = useState("");

  const elements = props.items.map((item, index) => {
    const spaceBefore = index - 1 < 0 ? "" : " ";
    const spaceAfter = index + 1 === props.items.length ? "" : " ";

    return item.className === "" ? (
      spaceBefore + item.text + spaceAfter
    ) : (
      <span key={item.text} className="important" ref={important}>
        {item.text}
      </span>
    );
  });

  function handleAnimate() {
    if (
      container.current.getBoundingClientRect().top -
        window.innerHeight / 1.1 <=
        0 &&
      isFirstTime
    ) {
      container.current.style.animationDelay = `0.35s`;
      setCurrentClass("fade-in-element");
      container.current.style.opacity = "1";
      if (important.current != null)
        important.current.className += " show-text-important";
      setCurrentClass("show-text");
      setReveal("reveal");
      setIsFirstTime(false);
      window.removeEventListener("scroll", handleAnimate);
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", handleAnimate);
    }
    watchScroll();
    handleAnimate();
    return () => {
      window.removeEventListener("scroll", handleAnimate);
    };
  });

  return (
    <h2 className={props.className}>
      <div ref={container} className={`${currentClass}`}>
        {elements}
        <span className={reveal}></span>
      </div>
    </h2>
  );
};

export default AnimateTitle;
