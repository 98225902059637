import React from "react";

const ServiceItem = ({ title, description, className }) => {
  return (
    <>
      {className && <div className={`service__img ${className}`}></div>}
      <p className="service__headline">{title.toUpperCase()}</p>
      <span className="service__description">{description}</span>
    </>
  );
};

export default ServiceItem;
