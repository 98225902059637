import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import AnimateSubTitle from "../Title/animateSubTitle";
import AnimateTitle from "../Title/animateTitle";
import Carousel from "./carousel";
import useComponents from "../useComponents";

const Clients = () => {
  const { rawData, logos } = useStaticQuery(query);
  const { clients } = useComponents(rawData);
  const images = logos.edges.map((img) => getImage(img.node.childImageSharp));

  return (
    <>
      <section className="container section__margin">
        <AnimateTitle
          items={clients.title}
          className="section__headline col-12 mb-3"
        />
        <AnimateSubTitle
          items={clients.sub_title}
          className="section__sub col-lg-8 col-12"
        />
      </section>
      <Carousel images={images} />
    </>
  );
};

export default Clients;

const query = graphql`
  query clientsQuery {
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            clients {
              sub_title {
                text
                className
              }
              title {
                className
                text
              }
            }
          }
        }
      }
    }
    logos: allFile(filter: { relativePath: { regex: "/.*-logo.*./" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              height: 350
              placeholder: BLURRED
              quality: 100
              formats: [WEBP]
            )
          }
        }
      }
    }
  }
`;
