import { useLocale } from "../hooks/locale";

function useComponents(rawData) {
  const { locale } = useLocale();

  const simplified = rawData.edges.map(({ node }) => {
    return {
      name: node.name,
      translations: node.translations,
    };
  });

  const { translations } = simplified.filter((lang) => lang.name === locale)[0];
  return translations;
}

export default useComponents;
