import React from "react";

const Client = (props) => {
  const size = props.size + "px";
  return (
    <div
      className="client-loc"
      style={{
        left: props.left + "%",
        top: props.top + "%",
      }}
    >
      <div className="client-place">
        <span style={{ display: "block", height: size, width: size }}></span>
      </div>
      <div className="client-place-info">
        <span className="country">{props.country}</span>
        <span className="number">{props.number}</span>
        <span className="number-label">&#32;{props.label}</span>
      </div>
    </div>
  );
};

export default Client;
