import React, { Component } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import * as styles from "./clients.module.css";

class Carousel extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 900,
      slidesToScroll: 1,
      slidesToShow: 5,
      adaptiveHeight: false,
      focusOnSelect: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider {...settings}>
          {this.props.images.map((img, index) => (
            <div key={index}>
              <div className={styles.wrapper}>
                <GatsbyImage
                  imgClassName={`img-fluid ${styles.img}`}
                  image={img}
                  alt="logos"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default Carousel;
