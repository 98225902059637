import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import AnimateTitle from "../Title/animateTitle";
import OdometerBox from "./odometerBox";
import useComponents from "../useComponents";

const Stats = () => {
  const el = useRef(null);
  const { rawData, numberData } = useStaticQuery(query);
  const { stats } = useComponents(rawData);
  const { stats_numbers } = numberData.edges[0].node.content;

  return (
    <section className="stats container text-center section__margin" ref={el}>
      <AnimateTitle items={stats.title} className="stats__headline" />
      {stats.content &&
        stats.content.map((item, index) => {
          return (
            <OdometerBox
              key={index}
              stats={el}
              text={item.text}
              number={stats_numbers[item.type]}
            />
          );
        })}
    </section>
  );
};

export default Stats;

const query = graphql`
  query statsQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/(components)/stats.*.mdx$/" } }
    ) {
      edges {
        node {
          fields {
            locale
          }
          body
        }
      }
    }
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            stats {
              content {
                type
                text
              }
              title {
                text
                className
              }
            }
          }
        }
      }
    }
    numberData: allFile(filter: { sourceInstanceName: { eq: "data" } }) {
      edges {
        node {
          content: childDataJson {
            stats_numbers {
              clients
              code
              projects
              people
            }
          }
        }
      }
    }
  }
`;
