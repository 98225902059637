import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AnimateSubTitle from "../Title/animateSubTitle";
import AnimateTitle from "../Title/animateTitle";
import useTranslations from "../useTranslations";
import useComponents from "../useComponents";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Client from "./client";

const Map = (props) => {
  const { rawData, mapImage, mapData, label } = useStaticQuery(query);
  const { map_clients } = useComponents(rawData);
  const { client, clients } = useTranslations(label);
  const img = getImage(mapImage);
  const { map_data } = mapData.edges[0].node.content;
  let maxClients = 0;

  const items = map_clients.content.map((item) => {
    const data = map_data.find((el) => el.code === item.code);
    maxClients = maxClients < data.number ? data.number : maxClients;

    return {
      text: item.text,
      left: data.left,
      top: data.top,
      number: data.number,
      label: parseInt(data.number) === 1 ? client : clients,
    };
  });

  return (
    <section className="container text-center section__margin">
      <AnimateTitle
        items={map_clients.title}
        className="col-12 mb-3 text-center"
      />
      <AnimateSubTitle
        items={map_clients.sub_title}
        className="col-lg-4 offset-lg-8 col-12  mb-5"
      />
      <div className="map-wrapper map-wrapper-dark">
        <div className="clients-map">
          <GatsbyImage
            imgClassName="map img-fluid"
            image={img}
            alt="map-clients"
          />
          <div className="client-locations">
            {items.map((item, index) => {
              return (
                <Client
                  key={index}
                  country={item.text}
                  left={item.left}
                  top={item.top}
                  number={item.number}
                  size={Math.ceil((item.number / maxClients) * 10)}
                  label={item.label}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Map;

const query = graphql`
  query mapQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/(components)/map.*.mdx$/" } }
    ) {
      edges {
        node {
          fields {
            locale
          }
          body
        }
      }
    }
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            map_clients {
              title {
                text
                className
              }
              sub_title {
                text
                className
              }
              content {
                code
                text
              }
            }
          }
        }
      }
    }
    mapImage: file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: BLURRED, formats: [WEBP])
      }
    }
    mapData: allFile(filter: { sourceInstanceName: { eq: "data" } }) {
      edges {
        node {
          content: childDataJson {
            map_data {
              code
              left
              top
              number
            }
          }
        }
      }
    }
    label: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            client
            clients
          }
        }
      }
    }
  }
`;
